






































import { jwt } from '@/auth';
import EventSelect from '@/components/EventSelect.vue';
import { serverURL } from '@/util';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    EventSelect,
  },
})
export default class extends Vue {
  serverURL = serverURL();

  get loginURL(): string {
    return `${serverURL()}auth/login?redirect=${this.redirectURL}`;
  }

  get logoutURL(): string {
    return `${serverURL()}auth/logout?redirect=${this.redirectURL}`;
  }

  get redirectURL(): string {
    return encodeURIComponent(`${window.location.origin}${process.env.BASE_URL}dashboard/`);
  }

  get isAuthed(): boolean {
    return !!jwt;
  }

  get isCaster(): boolean {
    return !!jwt?.user.roles.find((r) => ['admin', 'caster'].includes(r));
  }

  get isObserver(): boolean {
    return !!jwt?.user.roles.find((r) => ['admin', 'observer'].includes(r));
  }
}
